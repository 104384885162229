
.Header {

  nav {

    background-color: white; //#005cab; // IE 11
    // background-color: var(--peacock-blue) !important;

    margin-bottom: 0;

    border-bottom: 2px solid #38b39a;

    height: 56px;

    button {

      color: #fff; // IE 11
      color: var(--light-color-text);

      box-shadow: none;
      border: none;

      &.LeftBtn {
        background-color: white;
      }

      &.RightBtn {

        background-color: #005cab; // IE 11
        background-color: var(--peacock-blue) !important;

        font-family: ProximaNova, Helvetica, Arial, sans-serif;
        font-size: 18px;
        //font-weight: normal;
        //font-stretch: normal;
        //font-style: normal;
        line-height: 1.33;
        //letter-spacing: normal;
        text-align: right;

        //height:56px;
        height:30px !important;

        padding-left: .75rem;
        padding-right: .75rem;

        margin-right:.75rem;

        border-radius: 25px;

        &.DarkBackground {
          background-color: var(--dark-blue) !important;
        }
      }
    }
  }
}








