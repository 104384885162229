/* =============================================================================
   Primary Styles
   Author: rsacek
   ========================================================================== */


/* Imports */
@import "bootstrap.css";
@import "fonts/FontAwesome/solid";

/* =============================================================================
   Variables

   ========================================================================== */
:root {
  --light-color-text: #fff;
  --peacock-blue: #005cab;
  --colors-royal: #006699;
  --colors-gray: #333333;
  --greeny-blue: #38b39a;
  --slate-grey: #636466;
  --colors-navy: #17405a;
  --turquoise-blue: #00a2b8;
  --dark-blue: #004078;
  --brown-grey: #979797;
}



@font-face {
  font-family: 'FuturaPT-Medium';
  /*src: url('Futura_PT_Medium.eot');*/
  src:
          url('fonts/Futura_PT_Medium/Futura_PT_Medium.eot?#iefix') format('embedded-opentype'),
          url('fonts/Futura_PT_Medium/Futura_PT_Medium.woff2') format('woff2'),
          url('fonts/Futura_PT_Medium/Futura_PT_Medium.woff') format('woff'),
          url('fonts/Futura_PT_Medium/Futura_PT_Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}



@font-face {
  font-family: 'FuturaPT-Demi';
  //src: url('fonts/Futura_PT_Demi/Futura_PT_Demi.eot');
  src:
          url('fonts/Futura_PT_Demi/Futura_PT_Demi.eot?#iefix') format('embedded-opentype'),
          url('fonts/Futura_PT_Demi/Futura_PT_Demi.woff2') format('woff2'),
          url('fonts/Futura_PT_Demi/Futura_PT_Demi.woff') format('woff'),
          url('fonts/Futura_PT_Demi/Futura_PT_Demi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}



@font-face {
  font-family: 'ProximaNova';
  src: local( 'ProximaNova-Regular' ),
      url( fonts/ProximaNova/ProximaNova-Regular.otf ) format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}




//
// ** Zeplin **
//
.para-base-type-1 {
  font-family: ProximaNova, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}


.paragraphp3 {
  @extend .para-base-type-1;
}
.paragraphp4 {
  @extend .para-base-type-1;
  line-height: 1.25;
}

.paragraphp5 {
  @extend .para-base-type-1;
  font-size: 18px;
  line-height: 1.33;
}

.para-3 {
  @extend .para-base-type-1;
}

.para-4-emp {
  @extend .para-base-type-1;
  font-weight: bold;
  line-height: 1.25;
}

.para-5-emp {
  @extend .para-base-type-1;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
}

.para-6 {
  @extend .para-base-type-1;
  font-size: 18px;
}

.para-6-emp {
  @extend .para-base-type-1;
  font-size: 18px;
  font-weight: bold;
}



/* =============================================================================
   Global
   ========================================================================== */


html {
  height: 100%;
}

*:focus, textarea:focus, input:focus, button:focus {
	outline: 0 !important;
	outline: none !important;
	box-shadow: none !important;
}

body {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'ProximaNova', Helvetica, Arial, sans-serif;


  margin-top: 76px;
  //padding-top: 76px;
  // margin-top: calc( env(safe-area-inset-top, 0px) + 76px); // RJS fullscreen fix - ipad does not adjust.

  //margin-bottom: 96px;
  padding-bottom: 57px;

  &.no-overflow {
    overflow-x: hidden;
  }
}




/* =============================================================================
   Colors
   ========================================================================== */

.slate-grey {
  color: var(--slate-grey);
}

.green-blue {
  color: var(--greeny-blue);
}

/* =============================================================================
   Typography
   ========================================================================== */
h1, .h1 {
  font-family: FuturaPT-Medium, Helvetica, Arial, sans-serif;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.91;
  letter-spacing: normal;
}

h2, .h2 {
  font-family: FuturaPT-Medium, Helvetica, Arial, sans-serif;
  font-size: 28px;
  //font-weight: normal;
  //font-stretch: normal;
  //font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

h3, .h3 {
  font-family: FuturaPT-Demi, Helvetica, Arial, sans-serif;
  font-size: 18px;
  //font-weight: 600;
  //font-stretch: normal;
  //font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
}

h4, .h4 {
  font-family: FuturaPT-Demi, Helvetica, Arial, sans-serif;
  font-size: 18px;
  //font-weight: 600;
  //font-stretch: normal;
  //font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
}

//h5, .h5 {
//}
//
//h6, .h6 {
//}
//
//p {
//
//  &.lg {
//  }
//
//  &.md {
//  }
//
//	&.sm {
//	}
//}
//
//a {
//
//  &:hover {
//  }
//}


.action-link {
  color:var(--peacock-blue);
  &:hover {
    cursor: pointer;
  }
}



/* =============================================================================
   Buttons
   ========================================================================== */

button {

  &.no-btn-ui {
    background-color: white;
    border: none;
    background-image: none !important;
  }
}





.btn {

  &.btn-primary {

    font-family: ProximaNova, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;

    // height: 44px;
    min-height: 44px;
    height:auto !important;

    padding-left: 20px;
    padding-right: 20px;
    border-radius: 22px !important;

    white-space: normal;
    //text-shadow: none;
    transition: .2s;
    border: none;
    background-image: none !important;
    box-shadow: none;

    &.error-btn {
      height: auto;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    &:hover, &:focus, &.active, &:active {
    }
  }


  &.blue-btn {
    color: var(--light-color-text);

    background-color: #005cab;   // IE 11
    background-color: var(--peacock-blue);
  }
}

.action-btn {
  padding: 0;
  margin: 0;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}


/* =============================================================================
   iOS Fixes
   ========================================================================== */

/*
  Padding to fix iOS and Safari issues with content on bottom being cut off.
  This is not a Notch Issue.
  On iOS, when the bottom control bar is displayed (on iphones) the bar covers up the content.
  This is a known issue with Apple.
  iPads also have a similiar issue when calculating height of the content area.
 */
.ios-padding-fix,
.safari-padding-fix {
  padding-bottom: 80px;
  // background-color: red;
}




/* =============================================================================
   Icons
   ========================================================================== */
//i.fas, i.far {
//
//
//  &.sm {
//    color: var(--light-color-text) !important;
//    font-size: 1.4em;
//  }
//
//  &.lg {
//    font-size: 1.6em;
//  }
//}






/* =============================================================================
   Forms
   ========================================================================== */

.form-group .custom-select, .custom-control .custom-select {
  background: #fff url(../images/ico-dropdown.png) no-repeat right 0.75rem center;
  cursor: pointer;
  font-size: 1.4em;
  line-height: 1.5em;
}

.form-group .form-control, .custom-control .form-control, .form-group .custom-select, .custom-control .custom-select {
  // border-radius: 12px;
  border: 1px solid #d7d7d8;
  height: 44px;
  padding: 0 12px;
  font-size: 18px;
  line-height: 44px;
  color: var(--slate-grey);
}




//
// ** Checkboxes **
//
input[type=checkbox] {
  accent-color: var(--peacock-blue);
}

input[type="checkbox"] {
  box-sizing: content-box;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid var(--peacock-blue);
  vertical-align: bottom;
}

input[type="checkbox"]:checked {
  border: 1px solid var(--peacock-blue);
  background-color: var(--peacock-blue);
}

input[type="checkbox"]:checked:after {
  background-color: transparent;
  content:url('../images/icons/checkmark.svg');
  display:inline-block;
  transform: translateY(-7px) translateX(-5px) scale(.5);
}



/* =============================================================================
   Tables
   ========================================================================== */
//table {
//  &.table {
//
//  }
//
//  &.table-striped {
//  }
//}




/* =============================================================================
   Modal
   ========================================================================== */
//.modal {
//}



/* =============================================================================
   Header
   ========================================================================== */



/* =============================================================================
   Main
   ========================================================================== */





/* =============================================================================
   Footer
   ========================================================================== */





/* =============================================================================
   Media Queries
   ========================================================================== */


//@media only screen and (max-width: 1199px) {
//}
//
//@media only screen and (max-width: 1024px) {
//}
//
//@media only screen and (max-width: 991px) {
//}
//
//@media only screen and (max-width: 767px) {
//  h1, .h1 {
//  }
//
//  h2, .h2 {
//  }
//
//  h3, .h3 {
//  }
//
//  h4, .h4 {
//  }
//
//  h5, .h5 {
//  }
//
//  h6, .h6 {
//  }
//
//
//  p {
//
//    &.lg {
//    }
//
//    &.md {
//    }
//  }
//}

//@media only screen and (max-width: 575px) {
//}


//@media only screen and (max-width: 360px) {
//}


//@media only screen and (max-width: 300px) {
//}

//@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//}
