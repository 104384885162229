
.ServiceWorkerWrapper {
    width:100%;
    height: 100%;
    position: fixed;
    z-index:9000;
    left:0;
    top: 0;
    background-color: rgba(0,0,0,.40);
}

.UpgradeNoticeBox {

    color:red;

    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px var(--peacock-blue);
    background-color: #ffffff;

    width:100%;

    margin-top: 100px;

    display:inline-flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
}


