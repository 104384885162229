.SelectProductRow {

  padding-bottom: 20px;

  .SelectProduct {

    width: 158px;
    min-height: 100px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px var(--peacock-blue);
    background-color: #ffffff;

    display:inline-flex;
    justify-content: center;
    align-items: center;

    margin-left: 10px;
    margin-right: 10px;
  }
}

.bottomSection {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .buttonSection {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    max-width: 350px;
  }
}




@media only screen and (max-width: 360px) {

  .SelectProductRow {

    .SelectProduct {
      width: 140px !important;
      margin-left: 7px !important;
      margin-right: 7px !important;
    }
  }
}

@media only screen and (max-width: 300px) {

  .SelectProductRow {

    .SelectProduct {
      width: 120px !important;
      margin-left: 5px !important;
      margin-right: 5px !important;

      img {
        width:100px !important;
      }
    }
  }
}

