.ControlBar {

  background-color: #005cab;  // IE 11
  background-color: var(--peacock-blue);

  color: var(--light-color-text);


  padding-bottom: env(safe-area-inset-bottom, 0);   // iOS Notch Issue
}

.ControlBarBtnPanel {

  height: 57px;

  display: flex!important;
  justify-content: space-around;
  align-items: center;

  button {

    height: 90%;

    color: #fff; // IE 11
    color: var(--light-color-text);

    background-color: #005cab; // IE 11
    background-color: var(--peacock-blue);

    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;

    text-align: center;

    font-family: ProximaNova, Helvetica, Arial, sans-serif;
    font-size: 16px;
    //font-weight: normal;
    //font-stretch: normal;
    //font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;


    border-radius: 0 !important;
    border: none;
    background-image: none !important;


    &.SelectedBtn {
      border-bottom: 3px solid white !important;
    }


    i {
      margin-top: 2px;
      margin-bottom: 0;

      &.FasCustom {

        color: #fff;   // IE 11
        color: var(--light-color-text) !important;

        font-size: 18px;
        line-height: 1.33;
        letter-spacing: normal;
      }
    }

    .BtnText {
      margin-top: -3px;
    }

  }
}




@media only screen and (min-width: 360px) {

  .ControlBarBtnPanel {
    justify-content: center !important;

    button {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}
