
.Footer {

  position: relative;
  bottom: 0;

  margin-top:20px;
  margin-bottom:0;

  padding-bottom: 20px;
  padding-bottom: calc( env(safe-area-inset-bottom, 0) + 20px);   // iOS Notch Issue


  border-top: solid 1px #d3d4d6;
  background-color: #f2f2f2;
  //background-color: red;

  // This stretchs the footer to the bottom of the screen or
  // at least provides the illusion that it does with the border.
  box-shadow: 0 50vh 0 50vh #f2f2f2;
  //box-shadow: 0 50vh 0 50vh red;

  font-family: FuturaPT-Medium, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;

  .FooterCell {
    margin-top: 25px;

    a {
      color: var(--turquoise-blue);
      text-decoration: none;
    }
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0px;

    li {
      margin-top: 25px;

      a {
        color: var(--turquoise-blue);
        text-decoration: none;
      }
    }
  }
}

