.UpgradeNoticeBox {

    color:red;

    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px var(--peacock-blue);
    background-color: #ffffff;

    width:100%;

    display:inline-flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
}
