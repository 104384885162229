.Loader,
.Loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    background-color: white;
}
.Loader {
    background-color: white;
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0,92,171, 0.2);
    border-right: 1.1em solid rgba(0,92,171, 0.2);
    border-bottom: 1.1em solid rgba(0,92,171, 0.2);
    border-left: 1.1em solid #005cab;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.center {
    position: fixed;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 100%;*/
    /*height: 70vh;*/
    /*background-color: red;*/
    /*border: 3px solid green;*/
}